<template>
  <div class="stepperWrap">
    <button
      type="button"
      class="stepperBtn btn-minus"
      :class="{ dis: minDis }"
      @click="decrease"
    >
      -
    </button>
    <input
      type="text"
      class="stepperInput"
      v-model="stepInput"
      @blur="checkInput"
    />
    <button
      type="button"
      class="stepperBtn btn-plus"
      :class="{ dis: maxDis }"
      @click="add"
    >
      +
    </button>
  </div>
</template>

<script>
export default {
  name: "numStepper",
  props: {
    defaultValue: {
      require: false
    },
    min: {
      type: Number,
      default: 1
    },
    max: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      stepInput: 1,
      maxDis: false,
      minDis: true
    };
  },
  watch: {
    stepInput(val, old) {
      if (
        val !== "" &&
        (/\D/g.test(val) ||
          val < this.min ||
          (this.max !== -1 && val > this.max))
      ) {
        this.stepInput = old;
      } else {
        this.btnState();
        if (val !== "") this.$emit("change", this.stepInput);
      }
    },
    max(val) {
      if (val < this.stepInput) this.stepInput = val;
    },
    min(val) {
      if (val > this.stepInput) this.stepInput = val;
    }
  },
  created() {
    if (this.defaultValue !== undefined) {
      this.stepInput = this.defaultValue;
    }
    this.btnState();
  },
  methods: {
    //  增加
    add() {
      if (!this.maxDis) {
        this.stepInput++;
      } else {
        this.$emit("maxLimit", this.max);
      }
    },
    // 减少
    decrease() {
      if (!this.minDis) {
        this.stepInput--;
      } else {
        this.$emit("minLimit", this.min);
      }
    },
    //  按钮状态
    btnState() {
      this.minDis = this.stepInput <= this.min;
      this.maxDis = this.stepInput >= this.max && this.max != -1;
    },
    //  失焦时判断输入为空
    checkInput() {
      if (this.stepInput === "") {
        this.stepInput = this.defaultValue || this.min;
      }
    }
  }
};
</script>

<style lang="scss">
.stepperWrap {
  font-size: 0;
  display: inline-block;
  border-radius: 8px;
  /* prettier-ignore */
  border: 1PX solid #dadbdd;
  overflow: hidden;
  .stepperBtn {
    display: inline-block;
    width: 50px;
    height: 50px;
    background: #e7e8ea;
    color: #333;
    font-size: 26px;
    &.dis {
      color: #b2b2b2;
      background: #f2f4f5;
    }
  }
  .stepperInput,
  .stepperInput:focus {
    display: inline-block;
    padding: 0 10px;
    width: 80px;
    text-align: center;
    height: 50px;
    box-sizing: border-box;
    border: none;
    background: #fff;
    font-size: 26px;
    color: #000;
  }
}
</style>