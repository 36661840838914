<template>
  <div id="orderForm" class="fixedMode">
    <div class="mainScroll">
      <div class="topAddrWrap">
        <template v-if="!userAddrLoading"
          ><div class="addrWrap newAdd" v-if="!userAddr" @click="editAddr(0)">
            添加收货地址
          </div>
          <div class="addrWrap aAddr" v-else @click="editAddr(1)">
            <p class="name">
              {{ userAddr.name
              }}<span class="mobile">{{ userAddr.mobile }}</span>
            </p>
            <p class="addr">
              {{
                userAddr.provinceName +
                userAddr.cityName +
                userAddr.districtName +
                " " +
                userAddr.detailAddress
              }}
            </p>
          </div>
        </template>
      </div>
      <div class="partBg">
        <aOrderGoods
          :isShowNum="false"
          :buyCount="buyCount"
          :imgUrl="imgUrl"
          :goodsInfo="spuInfo"
          :skuAttr="skuAttr"
        ></aOrderGoods>
        <div class="orderDetail">
          <div class="orderDetailBar">
            <span class="itemName">购买数量</span>
            <div class="itemCon">
              <numStepper
                :defaultValue="buyCount"
                :max="!skuInfo || !skuInfo.limitCount ? -1 : skuInfo.limitCount"
                @change="buyCountUpdate"
              ></numStepper>
            </div>
          </div>
          <div class="orderDetailBar">
            <span class="itemName">配送方式</span>
            <div class="itemCon">快递 包邮</div>
          </div>
          <div class="orderDetailBar">
            <span class="itemName">发货时间</span>
            <div class="itemCon">现在下单，48小时内发货</div>
          </div>
          <div class="orderDetailBar" @click="openDiscount">
            <span class="itemName">选择优惠券</span>
            <div class="itemCon">{{ tipText }}</div>
          </div>
          <!-- <div class="orderDetailBar">
            <span class="itemName">下单优惠</span>
            <div class="itemCon">江苏移动尊享会员专享8折优惠</div>
          </div> -->
        </div>
      </div>
    </div>
    <!-- 优惠券选择 -->
    <transition name="selectMask-show">
      <div class="selectDiscount" v-show="disCountSelect">
        <div class="pullWrap">
          <!-- <div class="pullWrapbg"> -->
          <!-- </div> -->
          <div class="pullTitle">
            选择优惠券
            <span class="closeBtn" @click="disCountSelect = false"></span>
          </div>
          <div class="discountModule">
            <div
              class="discountItem"
              v-for="(discount, index) in disCountList"
              :key="index"
              ref="discountCode"
              v-show="!discount.available"
            >
              <div class="mc" v-if="selectCtrl(discount)"></div>
              <div class="itemTop" @click="selDiscount(discount, index)">
                <div class="itemTopLeft">
                  <p class="itemNum">{{ discount.couponPrice }}</p>
                  <p class="itemLimit">
                    {{ "满" + discount.useMinPrice + "可使用" }}
                  </p>
                </div>
                <div class="itemTopCenter">
                  <div class="name">
                    <span class="type">{{
                      discount.couponType == "0" ? "通用券" : "商品券"
                    }}</span
                    >{{ discount.couponTitle }}
                  </div>
                  <div class="limitData">
                    有效期至:{{ discount.couponEndTime.split(" ")[0] }}
                  </div>
                </div>
                <div class="itemTopRight">
                  <div ref="selBox" class="unsel"></div>
                </div>
              </div>
              <div class="itemBottom">
                <div class="seeDetail" @click="openDesc(index)">
                  <p>不可叠加使用</p>
                  <p ref="arrowDown">
                    查看详情<span class="arrowBottom"></span>
                  </p>
                </div>
                <div class="visiDetail" ref="detail" @click="closeDesc(index)">
                  <div class="discountItemBottom" ref="discountBottom" v-html="discount.statemen">
                    <!-- <p class="limitCategory">{{'限品类:'+discount.limitCategory}}</p>
                <p class="limitPlantform">{{'限平台:'+discount.limitPlantform}}</p> -->
                    <!-- {{ discount.statement }} -->
                  </div>
                  <p class="detailUp">查看详情<span class="arrowUp"></span></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <div class="bottomBtn">
      <p class="total">
        <span class="count">共计{{ buyCount || 0 }}件商品，</span>
        <span class="prize">
          合计
          <span class="num">{{ totalPrice }}</span>
        </span>
      </p>
      <button
        type="button"
        class="buybtn"
        :disabled="submiting"
        @click="submitOrder"
      >
        立即购买
      </button>
    </div>
  </div>
</template>

<script>
// import { mapState, mapActions } from "vuex";
import numStepper from "@/components/goods/numStepper";
import aOrderGoods from "@/components/goods/aOrderGoods";
export default {
  name: "orderForm",
  data() {
    return {
      spuId: this.$route.query.spuId,
      sceneNo: this.$route.query.sceneNo,
      spuInfo: null,
      skuInfo: null,
      imgUrl: "",
      buyCount: 1,
      skuAttr: null,
      userAddr: null,
      userAddrLoading: true,
      submiting: false,
      disCountSelect: false,
      disCountList: [],
      couponId: "",
      couponCollectionId: "",
      tipText: "请选择优惠券",
      discountNum: 0,
    };
  },
  computed: {
    // ...mapState({
    //   //  用户当前地址
    //   userAddr: (state) => state.userAddr
    // }),
    //  总价格
    totalPrice() {
      console.log(this.discountNum);
      var normalPrice = this.changeUnit(
        this.spuInfo &&
          this.spuInfo.currentPrice &&
          this.global.decimalPlace(
            this.spuInfo.currentPrice * this.buyCount -
              this.changeYuan(this.discountNum, 100),
            2
          )
      );
      return normalPrice > 0 ? normalPrice : 0 || 0;
    },
  },
  components: {
    numStepper,
    aOrderGoods,
  },
  created() {
    // 从选择商品页面跳转而来会有缓存 【防止此页面被分享
    let baseInfo = sessionStorage.getItem(`spuBuy${this.spuId}`);
    if (!baseInfo) {
      //  如果没有缓存 跳回详情页
      this.$router.replace({
        path: "/h/scm/mall/goodsDetail",
        query: {
          sceneNo: this.sceneNo,
          spuId: this.spuId,
        },
      });
      return;
    }
    baseInfo = JSON.parse(baseInfo);
    this.spuInfo = baseInfo.spuInfo;
    this.skuInfo = baseInfo.skuInfo;
    this.imgUrl = baseInfo.imgUrl || "";
    this.buyCount = baseInfo.buyCount || 1;
    this.skuAttr = baseInfo.skuAttr || {};
    this.getDefaultAddr();
  },
  mounted() {
    //  this.getDiscountList();
    this.getCountList();
  },
  methods: {
    getCountList() {
      var that = this;
      this.request
        .post(
          "/api/scm/store/coupon/use?amount=" +
            Number(that.changeUnit(this.spuInfo.currentPrice * this.buyCount))+'&goodId='+this.spuId,
          {
            amount: Number(
              that.changeUnit(this.spuInfo.currentPrice * this.buyCount)
            ),
            goodId:this.spuId
          }
        )
        .then((res) => {
          if (res&&res.length) {
            this.disCountList = res;
            var that = this;
            this.$nextTick(()=>{
              that.selDiscount(that.disCountList[0],0);
            })
          
          } else {
            this.tipText = "暂无可用优惠券";
          }
        });
    },
    changeYuan(yuan, digit) {
      var m = 0,
        s1 = yuan.toString(),
        s2 = digit.toString();
      try {
        m += s1.split(".")[1].length;
      } catch (e) {}
      try {
        m += s2.split(".")[1].length;
      } catch (e) {}
      return (
        (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
        Math.pow(10, m)
      );
    },
    changeUnit(fen) {
      var num = fen;
      num = fen * 0.01;
      num += "";
      var reg =
        num.indexOf(".") > -1
          ? /(\d{1,3})(?=(?:\d{3})+\.)/g
          : /(\d{1,3})(?=(?:\d{3})+$)/g;
      num = num.replace(reg, "$1");
      num = this.delDot(num);
      return num;
    },
    delDot(num) {
      var f = parseFloat(num);
      if (isNaN(f)) {
        return false;
      }
      var f = Math.round(num * 100) / 100;
      var s = f.toString();
      var rs = s.indexOf(".");
      if (rs < 0) {
        rs = s.length;
        s += ".";
      }
      while (s.length <= rs + 2) {
        s += "0";
      }
      return s;
    },
    //  selDiscount(discount,index){
    //   const {id,couponTitle,couponPrice,couponId} = discount;
    //   var selBox = this.$refs.selBox;
    //   for(let i=0;i<selBox.length;i++){
    //     selBox[i].className = 'unsel'
    //   }
    //  if( this.couponId == id){
    //     selBox[index].className='unsel'
    //     this.couponId = '';
    //     this.couponCollectionId ='';
    //     this.tipText='请选择优惠券';
    //      this.discountNum=0;
    //   }else{
    //      selBox[index].className='sel';
    //      this.couponId = couponId;
    //      this.couponCollectionId = id;
    //      this.tipText=couponTitle;
    //      this.discountNum=couponPrice;
    //   }

    // },
    selDiscount(discount, index) {
      const { id, couponTitle, couponPrice, couponId } = discount;
      var selBox = this.$refs.selBox;
      for (let i = 0; i < selBox.length; i++) {
        selBox[i].className = "unsel";
      }
      selBox[index].className = "sel";
      this.couponId = couponId;
      this.couponCollectionId = id;
      this.tipText = couponTitle;
      this.discountNum = couponPrice;
    },
    selectCtrl(discount) {
      const { id, couponTitle, couponPrice, useMinPrice } = discount;
      if (
        this.spuInfo.currentPrice - this.changeYuan(useMinPrice, 100) > 0 ||
        this.spuInfo.currentPrice - this.changeYuan(useMinPrice, 100) == 0
      ) {
        return false;
      } else {
        return true;
      }
    },
    openDesc(index) {
      var detailItem = this.$refs.detail;
      var arrowDown = this.$refs.arrowDown;
      arrowDown[index].className = "visiBity";
      detailItem[index].className = "detail";
    },
    closeDesc(index) {
      var detailItem = this.$refs.detail;
      var arrowDown = this.$refs.arrowDown;
      arrowDown[index].className = "";
      detailItem[index].className = "visiDetail";
    },
    // 获取优惠券列表
    getDiscountList() {
      this.request.post("/api/scm/store/coupon/list", {}).then((res) => {
        if (res.list.length != 0) {
          this.disCountList = res.list;
          this.selDiscount(this.disCountList[0],0);
        
          //  this.$nextTick(()=>{
          //     try{

          //       this.disCountList.forEach((item,index)=>{
          //         if(this.spuInfo.currentPrice-this.changeYuan(item.useMinPrice,100)>0||this.spuInfo.currentPrice-this.changeYuan(item.useMinPrice,100)==0){
          //           this.selDiscount(item,index);
          //           throw new Error('找到了');
          //         }else{
          //            this.tipText = '暂无可用优惠券'
          //         }
          //       })
          //     }catch (e) {
          //       console.log('cuole')
          //     };
          // });
        } else {
          this.tipText = "暂无可用优惠券";
        }
      });
    },
    //  购买数量更新
    buyCountUpdate(val) {
      this.buyCount = val;
      // 更新缓存中的购买数量
      let baseInfo = sessionStorage.getItem(`spuBuy${this.spuId}`);
      baseInfo = JSON.parse(baseInfo);
      baseInfo.buyCount = val;
      sessionStorage.setItem(`spuBuy${this.spuId}`, JSON.stringify(baseInfo));
    },
    //  编辑/添加新地址
    editAddr(type) {
      //  选择地址
      this.$router.push({
        path: "/h/scm/member/myAddr",
        query: {
          spuId: this.spuId,
          sceneNo: this.sceneNo,
          // curAddrId: (this.userAddr && this.userAddr.id) || null
        },
      });
    },
    //  获取默认地址
    async getDefaultAddr() {
      let selectAddr = sessionStorage.getItem(`${this.spuId}Addr`); //  订单选择地址存在缓存中
      if (!!selectAddr) {
        this.userAddr = JSON.parse(selectAddr);
        this.userAddrLoading = false;
        return;
      }
      //  没有上次选择结果 找vuex中的默认地址
      let _defaultAddr = this.$store.state.defaultAddr;
      if (!!_defaultAddr) {
        this.userAddr = _defaultAddr;
        this.userAddrLoading = false;
        return;
      }
      try {
        let res = await this.request.get("/api/scm/user/address/default");
        if (res && res.id) {
          this.userAddr = res;
          this.$store.commit("UPDATEDEFAULTADDR", res);
        }
        this.userAddrLoading = false;
      } catch (e) {
        this.userAddrLoading = false;
      }
      console.log(this.$store.state.defaultAddr);
    },
    // ...mapActions(["getUserAddr"])
    // 提交订单
    submitOrder() {
      //  没有选择地址
      if (!this.userAddr || !this.userAddr.id) {
        this.$toast({
          message: "请添加收货地址",
          position: "bottom",
        });
        return;
      }
      if (!this.skuInfo || !this.skuInfo.skuId) {
        this.$toast({
          message: "商品信息有误，请重新下单",
          position: "bottom",
        });
        return;
      }
      let orderId = this.global.createOrderId(
        this.userAddr.uid,
        this.skuInfo.skuId
      );
      console.log(this.skuInfo);
      this.request
        .post("/api/scm/order/v2/placeOrder", {
          bizId: orderId,
          sceneNo: this.sceneNo,
          couponId: this.couponId,
          phoneNum: localStorage.getItem("phoneNum"),
          placeSkuList: [
            {
              couponCollectionId: this.couponCollectionId,
              skuId: this.skuInfo.skuId,
              skuName: this.skuInfo.skuName,
              placeNum: this.buyCount,
            },
          ],
          userAddressId: this.userAddr.id,
        })
        .then((res) => {
          if (res) {
            //  实付订单非零元需要付款流程
            if (!!res.actualAmount) {
              sessionStorage.setItem(`${orderId}Order`, JSON.stringify(res));
              // 跳转订单支付页面
              this.$router.replace({
                path: "/h/scm/order/orderPay",
                query: {
                  sceneNo: this.sceneNo,
                  channelNo: localStorage.getItem("channel"),
                  spuId: this.spuId,
                  bizId: orderId,
                  skuId: this.skuInfo.skuId,
                },
              });
            } else {
              this.pay0(orderId);
            }
          } else {
            this.submiting = false;
          }
        });
    },
    pay0(orderId) {
      let payBizId = orderId + String(new Date().getTime()).substr(-4, 4);
      this.request
        .postFrom(
          `/api/scm/pay/order?bizId=${orderId}&payType=1&payBizId=${payBizId}`,
          {}
        )
        .then((res) => {
          if (res) {
            this.$toast({
              message: "购买成功",
              position: "bottom",
            });
            this.$router.replace({
              path: "/h/scm/order/orderDetail",
              query: {
                skuId: this.skuInfo.skuId,
                bizId: orderId,
              },
            });
          }
          this.submiting = false;
        });
    },
    //选择优惠券
    selectDiscount(index, discount) {
      const { id, couponTitle, couponPrice, couponId } = discount;
      var discountCard = this.$refs.discountCode;
      if (this.spuInfo.currentPrice - this.changeYuan(couponPrice, 100) > 0) {
        for (let i = 0; i < discountCard.length; i++) {
          discountCard[i].className = "discountItem";
        }
        discountCard[index].className = "discountSelItem";
        this.couponId = couponId;
        this.couponCollectionId = id;
        this.tipText = "您已选择" + couponTitle;
        this.discountNum = couponPrice;
      } else {
        this.$toast({
          message: "该卡券不满足使用条件",
          position: "bottom",
        });
      }
    },
    openDiscount() {
      if (this.disCountList.length == "0") {
        return;
      }
      this.disCountSelect = true;
    },
  },
  beforeDestroy() {
    //  销毁可能存在的缓存
    sessionStorage.removeItem(`${this.spuId}Addr`);
  },
};
</script>

<style lang="scss" scoped>
.unsel {
  width: 38px;
  height: 38px;
  background: url("~@/assets/userCenter/unsel.png") no-repeat;
  background-size: 100% 100%;
}
.sel {
  width: 38px;
  height: 38px;
  background: url("~@/assets/userCenter/sel.png") no-repeat;
  background-size: 100% 100%;
}
.fixedMode {
  background: #efefef;
}
.visiBity {
  visibility: hidden;
}
.arrowBottom {
  display: inline-block;
  width: 18px;
  height: 11px;
  background: url("~@/assets/userCenter/arrowDown.png") no-repeat;
  background-size: 100% 100%;
}
.arrowUp {
  display: inline-block;
  width: 18px;
  height: 11px;
  background: url("~@/assets/userCenter/arrowUp.png") no-repeat;
  background-size: 100% 100%;
}
.fixedMode {
  background: #efefef;
}

.topAddrWrap {
  min-height: 185px;
  margin: 20px;
  background: #fff;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  &::after {
    content: "";
    display: block;
    bottom: 0;
    left: 0;
    right: 0;
    height: 10px;
    position: absolute;
    background: linear-gradient(
        135deg,
        transparent 15%,
        #f76a84 15%,
        #f76a84 45%,
        transparent 45%,
        transparent 60%,
        #6ab3f7 60%,
        #6ab3f7 90%,
        transparent 90%
      )
      center 100% / 112px 10px;
  }
  .addrWrap {
    box-sizing: border-box;
    &.newAdd {
      height: 175px;
      padding-top: 118px;
      text-align: center;
      color: #888;
      font-size: 28px;
      background: url(~@/assets/icons/addAddr.png) center 35px / 52px auto
        no-repeat;
      line-height: 1;
    }
    &.aAddr {
      min-height: 175px;
      padding: 15px 20px 25px 125px;
      margin-right: 30px;
      background: url(~@/assets/icons/addr.png) 50px center / 35px auto
          no-repeat,
        url(~@/assets/icons/right-arrow.png) right center / auto 25px no-repeat;
      .name {
        font-size: 30px;
        white-space: nowrap;
        line-height: 50px;
        margin-bottom: 10px;
        .mobile {
          font-size: 24px;
          color: #999;
          display: inline-block;
          margin-left: 25px;
        }
      }
      .addr {
        line-height: 30px;
        font-size: 24px;
        color: #333;
        padding-right: 100px;
      }
    }
  }
}

.partBg {
  background: #fff;
  border-radius: 20px;
  padding: 30px 30px 20px;
  margin: 0 20px 30px;
}

.goodsWrap {
  margin-bottom: 35px;
}
.orderDetail .orderDetailBar {
  min-height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .itemName {
    display: inline-block;
    color: #666;
    margin-right: 20px;
    font-size: 26px;
  }
  .itemCon {
    font-size: 26px;
  }
}
.bottomBtn {
  height: 160px;
  display: flex;
  background: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  .total {
    font-size: 30px;
    flex: 1;
    .count {
      color: #999;
      font-size: 26px;
    }
    .prize {
      display: inline-block;
      .num {
        color: #ea3d39;
        font-size: 36px;
        &::before {
          content: "¥ ";
          font-size: 30px;
        }
      }
    }
  }
  .buybtn {
    width: 275px;
    height: 96px;
    display: block;
    border-radius: 69px;
    background: linear-gradient(to right, #f62300, #fa5421);
    color: #fff;
    font-size: 36px;
    margin-left: 20px;
  }
}
.selectDiscount {
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  z-index: 12;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 750px;
  height: 100vh;
  .pullWrap {
    width: 750px;
    position: absolute;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    max-height: 100%;
    background: #f5f5f5;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    overflow: hidden;
    .pullTitle {
      width: 750px;
      height: 100px;
      font-size: 32px;
      line-height: 100px;
      text-align: center;
      color: #000;
      font-weight: 500;
      border-bottom: 1px solid #000;
      position: relative;
      .closeBtn {
        width: 55px;
        height: 55px;
        display: block;
        background: url(~@/assets/icons/closeBtn.png) left bottom / 35px auto
          no-repeat;
        position: absolute;
        right: 10px;
        top: 5px;
        z-index: 1;
      }
    }
    // .discountModule{
    //    width: 710px;
    //    height: 600px;
    //   //  border-radius: 20px;
    //    background: #f5f5f5;
    //    margin:0 auto;
    //    overflow: auto;
    //   //  margin-bottom: 20px;
    //   .discountSelItem{
    //     border: 1px solid yellow;
    //   }
    //   .discountItem,.discountSelItem{
    //     width: 710px;
    //     height: 160px;
    //     margin:20px 0;
    //     background: #fff;
    //     box-sizing: border-box;
    //     display: flex;
    //     display: -webkit-flex;
    //     justify-content: space-between;
    //     .itemLeft{
    //       width: 200px;
    //       height: 160px;
    //       display: flex;
    //       display: -webkit-flex;
    //       flex-direction: column;
    //       justify-content: center;
    //       align-items: center;
    //       font-size: 24px;
    //       .itemNum{
    //         font-size: 40px;
    //         font-weight: bold;
    //         position: relative;
    //         margin-bottom: 10px;
    //       }
    //       .itemNum::before{
    //         content: '￥';
    //         position: absolute;
    //         font-size: 18px;
    //         left: -20px;
    //         bottom: 8px;
    //         z-index: 1;
    //       }
    //     }
    //     .discountCenter{
    //       width: 320px;
    //       height: 160px;
    //       display: flex;
    //       display: -webkit-flex;
    //       flex-direction: column;
    //       justify-content: space-around;
    //       // align-items: center;
    //       font-size: 24px;
    //       .discountType{
    //           // display: flex;
    //           // display: -webkit-flex;
    //           .type{
    //             font-size: 24px;
    //              padding: 5px;
    //              display: inline-block;
    //              background: rgba(0, 0, 0, 0.4);
    //              }
    //           .name{
    //                font-size: 28px;
    //               }
    //       }
    //       .limit{
    //         li{
    //           // list-style: disc;
    //         }
    //       }

    //     }
    //     .btnWrap{
    //       width: 190px;
    //       height: 160px;
    //       display: flex;
    //       display: -webkit-flex;
    //       justify-content: center;
    //       align-items: center;
    //       .btn{
    //       width: 100px;
    //       height: 40px;
    //       text-align: center;
    //       line-height: 40px;
    //       font-size: 24px;
    //       color:rgb(200, 173, 172);
    //       border: 1px solid red;
    //       border-radius: 100px;
    //     }
    //     }

    //   }
    // }
    .discountModule {
      height: 645px;
      overflow-y: auto;
      .discountItem,
      .unDiscountItem {
        width: 710px;
        //   height: 160px;
        margin: 20px auto;
        background: #f7deaf;
        box-sizing: border-box;
        border-radius: 0.1rem;
        position: relative;
        .mc {
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 0.1rem;
          background: rgba(255, 255, 255, 0.6);
          z-index: 2;
        }
        .itemTop {
          height: 130px;
          width: 634px;
          padding: 0 38px;
          display: flex;
          display: -webkit-flex;
          border-bottom: 1px dashed #dcb268;
          align-items: center;
          .itemTopLeft {
            width: 200px;
            height: 100%;
            display: flex;
            display: -webkit-flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .itemNum {
              font-size: 48px;
              color: #8b5d0e;
              font-weight: bold;
              position: relative;
            }
            .itemNum::before {
              content: "￥";
              position: absolute;
              top: 5px;
              left: -20px;
              z-index: 1;
              font-size: 22px;
            }
            .itemLimit {
              font-size: 20px;
              color: #c3a167;
            }
          }
          .itemTopCenter {
            width: 370px;
            display: flex;
            display: -webkit-flex;
            flex-direction: column;
            justify-content: center;
            .name {
              font-size: 20px;
              color: #777065;
              .type {
                font-size: 30px;
                color: #000000;
                font-weight: bold;
              }
            }
            .limitData {
              padding-top: 15px;
              font-size: 22px;
              color: #e7ca98;
            }
          }
          .itemTopRight {
            // font-size: 24px;
            // width: 130px;
            // height: 48px;
            // color: #c69865;
            // background: #eaca96;
            // border-radius: 20px;
            // text-align: center;
            // line-height: 48px;
          }
        }
        .itemBottom {
          min-height: 50px;
          width: 635px;
          margin: 0 auto;
          .seeDetail {
            height: 50px;
            display: flex;
            display: -webkit-flex;
            justify-content: space-between;
            align-items: center;
            p {
              font-size: 20px;
              color: #8b5d0e;
            }
          }
          .visiDetail {
            position: relative;
            display: none;
            .discountItemBottom {
              width: 635px;
              margin: 0 auto;
              padding: 20px 0;
              font-size: 20px;
              color: #b08b4c;
            }
            .detailUp {
              position: absolute;
              right: 0;
              bottom: 20px;
              font-size: 20px;
              color: #8b5d0e;
            }
          }
          .detail {
            position: relative;
            display: block;
            .discountItemBottom {
              width: 635px;
              margin: 0 auto;
              padding: 20px 0;
              font-size: 20px;
              color: #b08b4c;
            }
            .detailUp {
              position: absolute;
              right: 0;
              bottom: 20px;
              font-size: 20px;
              color: #8b5d0e;
            }
          }
        }
      }
    }
  }
}
</style>